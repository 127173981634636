
export const  ServicesData  = [
     
         {
            id: 1,
            name: 'Shared Hosting',
            subtitle: 'Best for Starter',
            space: '1.25 GB Space',
            band: '25 GB Bandwidth',
            domain: 'Free Domain .com .org',
            email: '10 Emails',
            ssl: 'Free SS Certificate',
            lock: 'Free site lock',
            tieft: 'Free tieft Protection',
            cpanel: 'cPanel Account',
            core: 'Free Migration',
            price: 1, 
            cent: 25,
            reguller: 1.75,
            link: 'https://shahhost.com/order/index.php?/cart/ssd-hosting/'
            
        },
        
        {
            id: 2,
            name: 'Cloud Hosting',
            subtitle: 'For small, growing businesses on a budget',
            space: 'Unmetered SSD Disk Space',
            band: 'Unmetered Bandwidth',
            email: 'Unlimited Emails',
            site: 'Host 1 Website',
            core: '2 CPU Cores',
            domain: '2 GB RAM',
            ssl: 'Free SS Certificate',
            lock: 'Supports 25,000 visits/mo',
            cpanel: 'Free cPanel',
            price: 7,
             cent: 75,
            reguller: 18.45,
            link: 'https://shahhost.com/order/index.php?/cart/cloud-hosting-/',
        },
        {
            id: 3,
            name: 'VPS server',
            subtitle: 'High performance SSD VPS powered by KVM',
            space: '20 GB SSD Disk Space',
            band: '1 TB Bandwidth',
            email: 'Unlimited Emails',
            site: 'Host 1 Website',
            core: '2 CPU Cores',
            domain: '2 GB RAM',
            ssl: 'Free SS Certificate',
            lock: 'Free Micration',
            cpanel: 'Neustar’s DDoS protection',
            price: 12, 
            cent: 75,
            reguller: 17.75,
            link: 'https://shahhost.com/order/index.php?/cart/vps-hosting',
        },
        {
            id: 4,
            name: 'All In One',
            subtitle: 'Includes Hosting + Domain + Design',
            space: '1.5 GB Space',
            band: '25 GB Bandwidth',
            domain: 'Free Domain .com .org',
            email: 'Single Emails',
            ssl: 'Free SS Certificate',
            lock: 'Free site lock',
            tieft: 'Free tieft Protection',
            cpanel: 'cPanel Account',
            core: '5 Pages Design',
            price: 9, 
            cent: .60,
            reguller: 17.99,
            link: 'https://shahhost.com/order/index.php?/cart/all-in-one-website-packages/',
            
        },
        {
            id: 5,
            name: 'Wordpress Ht',
            subtitle: 'Gateway to WordPress on Cloud',
            space: '5 GB Disk Space',
            band: 'WP Database Optimized',
            email: 'Unlimited Emails',
            site: 'Host 1 Website',
            core: '2 CPU Cores',
            domain: '2 GB RAM',
            ssl: 'Automatic Updates',
           lock: 'Supports 25,000 visits/mo',
           cpanel: 'Jetpack Plugin pre-installed',
            price: 4,
             cent: 80,
            reguller: 6.75,
            link: 'https://shahhost.com/order/index.php?/cart/wordpress-hosting-/',
        },
        {
            id: 6,
            name: 'Dedicated Servers',
            subtitle: 'Dedicated SSD Hosting with Complete Root Access',
            space: 'Intel Xeon D-2141 I',
            band: '16 GB RAM',
            email: '1 Dedicated IP +2 Free IPs',
            site: 'Full Root Access',
            core: '500 GB SSD in RAID 1',
            domain: '5 TB Data Transfer',
            ssl: 'Instant Provisioning',
            lock: '99.9 Uptime Guaranty ',
            cpanel: '30 Day Money-Back policy',
            price: 187,
             cent: 75,
            reguller: 199.99,
            link: 'https://shahhost.com/order/index.php?/cart/dedicated-servers-imported-/',
        },
        {
            id: 7,
            name: 'Reseller Prog',
            subtitle: 'ُStart your own Web Hosting Business',
            space: '50 GB SSD Disk Space',
            band: '500 GB Bandwidth',
            email: 'Unlimited Emails',
            site: '50 cPanel Accounts',
            core: '2 CPU Cores',
            domain: '2 GB RAM',
            ssl: 'Free SS Certificate',
            lock: 'Free Micration',
            cpanel: 'Neustar’s DDoS protection',
            price: 12, 
            cent: 75,
            reguller: 17.75,
            link: 'https://shahhost.com/order/index.php?/cart/reseller-programes',
        },
        {
            id: 8,
            name: 'Branding Plane',
            subtitle: 'Give your business unique Brand',
            space: 'Logo',
            band: 'Company Profile',
            email: 'Office Stationary',
            site: 'Brouchor',
            core: 'Poster',
            domain: 'Business Card',
            ssl: 'ID Card',
            lock: '2 Stand Banner',
            cpanel: 'Stickers',
            price: 299, 
            cent: 99,
            reguller: 650,
            link: 'https://shahhost.com/order/index.php?/cart/branding-planes',
        },
  
    
]