import React from 'react';
import './SideDrower.style.scss';
import {Link} from 'react-router-dom'
import {Button} from '../../Button';
import {FaHome, FaRocket, FaEnvelope, FaLock, FaGlobe, FaLaptopCode, FaBezierCurve, FaPlug, FaAddressCard, FaUser, FaBook, FaCartArrowDown} from 'react-icons/fa'
import {FormattedMessage} from "react-intl";
// import '../dropDoenItems.css'

const SideDrower = () =>{
    const Domain = 'https://shahhost.com/order/index.php?/cart/'
    

    
    return (
            <div className="main-drower i">
            <ul className="main-ul-drower">
           
      
            <Link to='/' className="items-drower"><FaHome className=" iconn " /><FormattedMessage id="home" defaultMessage="Home" /></Link>
                <a href={`${Domain}ssd-hosting/`} className="items-drower"><FaRocket className=" iconn e" /><FormattedMessage id="hosting" defaultMessage="Hosting" /></a>
                <a href={`https://shahhost.com/order/index.php?/checkdomain/register-your-domain-now/`} className="items-drower"><FaGlobe className=" iconn e" /><FormattedMessage id="domain" defaultMessage="Domain" /></a>
                <a href={`${Domain}business-email-pro/`} className="items-drower"><FaEnvelope className=" iconn" /><FormattedMessage id="email-hosting" defaultMessage="Email Hosting" /></a>
                <a href={`${Domain}ssl-certificates-/`} className="items-drower"><FaLock className=" iconn" /><FormattedMessage id="ssl-certificates" defaultMessage="SSL Certificates" /></a>
                <a href={`${Domain}all-in-one-website-packages/`} className="items-drower"><FaLaptopCode className="iconn" /><FormattedMessage id="web-development" defaultMessage="Web Development" /></a>
                <a href={`/graphic-design`} className="items-drower"><FaBezierCurve className=" iconn" /><FormattedMessage id="graphic-designing" defaultMessage="Graphic Designing" /></a>
                <a href={`${Domain}website-builder`} className="items-drower"><FaPlug className=" iconn" /><FormattedMessage id="plugins" defaultMessage="Plugins" /></a>
                <a href={`${Domain}/email-spam-filtering`} className="items-drower"><FaLock className=" iconn" /><FormattedMessage id="email-security" defaultMessage="Email Security" /></a>
                <a href={`https://shahoster.com/portfolio`} className="items-drower"><FaAddressCard className=" iconn" /><FormattedMessage id="portfolio" defaultMessage="Portfolio" /></a>
                <a href={`https://shahhost.com/order/index.php?/tickets/new/`} className="items-drower"><FaUser className="social2"/><FormattedMessage id="support" defaultMessage="Support" /></a>
                <a href="https://shahhost.com/order/blog" className="items-drower"><FaBook className="social2"/><FormattedMessage id="blog" defaultMessage="Blogs" /> </a>
                <a href={`https://shahhost.com/order/index.php?/login`}>  <Button  id="signIn" title={<FormattedMessage id="sign-in" defaultMessage="Sign In" />}/></a>
                <a href={`https://shahhost.com/order/index.php?/signup/`}>  <Button  className="signUp" title={<FormattedMessage id="sign-up" defaultMessage="Sign Up" />}/></a>
                <a href={`https://shahhost.com/order/index.php?/cart/`}><FaCartArrowDown className="cart"/></a> 
            </ul>
        </div>
        
    )
}

export default SideDrower;