import React, {useContext} from 'react'
import './Language.style.css';
import {Context} from '../../Languages/Wraper';
import {withRouter} from 'react-router-dom';
import { FaGlobe } from "react-icons/fa";

const Language = (props) => {
    const context = useContext(Context);
    
    return (
        <div className="lang-container">
            <FaGlobe className="faGlob" />
            {/* <div className="selectLanguage">
                <FormattedMessage id="language" defaultMessage="Select Language"></FormattedMessage>
            </div> */}
               
            <div class="select">
                <select id="standard-select" value={context.locale} onChange={context.selectLang}>
                    <option  value="en">English</option>
                    <option  value="pa">پښتو</option>
                    <option  value="da">دری</option>
                </select>
            </div>
        </div>
    )
    
}

export default withRouter (Language);
