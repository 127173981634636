import React from 'react';
import './WhyUsCard.style.scss'



const WhyUsCards = ({title, icon, contents}) =>{
    return (
        <div className="why-us-card-container">
            <img className='why-us-card-icon' src={icon} alt=""/>
            <div className="why-us-contents">
                <h4 className="why-us-title">{title}</h4>
                <div className="why-us-text">{contents}</div>
            </div>
        </div>
    )
}

export default WhyUsCards;