import React from 'react';
import './Footer.style.scss';
import Logo from '../../images/shahhost-logo.svg';
import Card from '../../images/credit-cards.png';
import { FaRocket, FaEnvelope, FaLock, FaGlobe, FaLaptopCode, FaBezierCurve, FaPlug, FaAddressCard, FaPhone, FaMapMarker, FaAddressBook} from 'react-icons/fa'
import Mailchimp from 'react-mailchimp-form';
import { FormattedMessage } from 'react-intl';

const Footer = () =>{
    const Domain = 'https://shahhost.com/order/index.php?/cart/';

    return (
        <footer>
            <div className="footer-containiner">
                <div className="col-left">
                    <h3><FormattedMessage id="reach-us" defaultMessage="Reach Us" /></h3>
                    <div className="contact">
                        <FaPhone className="icon"/>
                        AFG: +93-20-211-0123 <br/>
                        &nbsp; &nbsp; &nbsp; &emsp; &emsp; +93-799-888-952 <br/>
                        &nbsp;  &emsp; USA: +1-346-270-5687
                </div>
                    <div className="contact">
                        <FaEnvelope className="icon"/>
                        sales@shahhost.com <br/>
                        &nbsp; &emsp; billing@shahhost.com <br/>
                        &nbsp; &emsp; support@shahhost.com
                    </div>
                    <div className="contact">
                        <FaMapMarker className="icon"/>
                        <FormattedMessage id="address" defaultMessage="Daudzai Business Center," /> <br/>
                        &nbsp; &emsp;<FormattedMessage id="address-2" defaultMessage="Malik Asghar Square," /> <br/>
                        &nbsp; &emsp;<FormattedMessage id="address-3" defaultMessage="Kabul, Afghanistan." /> <br/>
                     
                    
                    </div>


                </div>
                <div className="col-middle">
                    <h3><FormattedMessage id="our-services" defaultMessage="Our Services" /></h3>
                    <div className="footer-main">
                        <ul className="footer-main-ul">
                            <li className='f-li'> 
                                <a href={`${Domain}ssd-hosting/`}>
                                    <FaRocket className="f-items"/><FormattedMessage id="hosting" defaultMessage="Hosting" />
                                </a>
                            </li>
                            <li className='f-li'> 
                                <a href={`https://shahhost.com/order/index.php?/checkdomain/register-your-domain-now/`}>
                                     <FaGlobe className="f-items"/><FormattedMessage id="domain" defaultMessage="Domain" />
                                </a>
                            </li>
                            <li className='f-li'> 
                                <a href={`${Domain}business-email-pro/`}>
                                      <FaEnvelope className="f-items"/><FormattedMessage id="email-hosting" defaultMessage="Email Hosting" />
                                </a>
                            </li>
                            <li className='f-li'> 
                                <a href={`${Domain}ssl-certificates-/`}>
                                  <FaLock className="f-items"/><FormattedMessage id="ssl-certificates" defaultMessage="SSL Certificates" />
                                </a>
                            </li>
                            <li className='f-li'>
                                <a href={`${Domain}all-in-one-website-packages/`}>
                                  <FaLaptopCode className="f-items"/><FormattedMessage id="web-development" defaultMessage="Web Development" />
                                </a>
                            </li>
                            <li className='f-li'>
                                <a href={`graphic-design`}>
                                     <FaBezierCurve className="f-items"/><FormattedMessage id="graphic-designing" defaultMessage="Hosting" />
                                </a>
                            </li>
                            <li className='f-li'> 
                                <a href={`${Domain}website-builder`}>
                                     <FaPlug className="f-items"/><FormattedMessage id="plugins" defaultMessage="Digital Marketing" />
                                </a>
                            </li>
                            <li className='f-li'> 
                                <a href={`${Domain}cart.php?gid=5`}>
                                    <FaAddressCard className="f-items"/><FormattedMessage id="all-in-one" defaultMessage="All In One Plans" />
                                </a>
                            </li>
                            <li className='f-li'>
                                <a href={`${Domain}email-spam-filtering`}>
                                      <FaLock className="f-items"/><FormattedMessage id="email-security" defaultMessage="Email Security" />
                                </a>
                            </li>
                            <li className='f-li'> 
                                <a href={`https://shahoster.com/portfolio`}>
                                    <FaAddressBook className="f-items"/><FormattedMessage id="portfolio" defaultMessage="Portfolio" />
                                </a>
                            </li>
                            <li className='f-li'> 
                                <a href={`https://shahhost.com/order/index.php?/checkdomain/register-your-domain-now/`}>
                                    <FaAddressCard className="f-items"/><FormattedMessage id="whois" defaultMessage="Whois Lookup" />
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="col-middle2">
                <h3><FormattedMessage id="f-support" defaultMessage="Support" /></h3>
                    <div className="footer-main">
                        <ul className="footer-main-ul">
                           <li className='f-li'>
                              <a href="https://shahhost.com/order/index.php?/page/about-us/"> <FormattedMessage id="about" defaultMessage="About Us" /> </a>
                           </li>
                           <li className='f-li'>  
                             <a href="https://shahhost.com/order/index.php?/page/data-center"><FormattedMessage id="data-center" defaultMessage="Data Center" /></a>
                            </li>
                           <li className='f-li'>  
                                <a href="https://shahhost.com/order/index.php?/knowledgebase/">  <FormattedMessage id="announcements" defaultMessage="Annauncements" /></a>
                           </li>
                           <li className='f-li'>
                                <a href="https://shahhost.com/order/index.php?/knowledgebase/"><FormattedMessage id="knowladge-base" defaultMessage="Knawladgebass" /></a>
                            </li>
                           <li className='f-li'> 
                                <a href="https://shahhost.com/order/index.php?/knowledgebase/"><FormattedMessage id="faq" defaultMessage="FAQ" /> </a>
                            </li>
                           <li className='f-li'> 
                                 <a href="https://shahhost.com/order/index.php?/page/domain-registration-agreement/"> <FormattedMessage id="privacy" defaultMessage="Privacy & Policy" /></a>
                            </li>
                           <li className='f-li'>
                                <a href="https://shahhost.com/order/index.php?/tickets/new/"><FormattedMessage id="support" defaultMessage="Support" /></a>
                            </li>
                           <li className='f-li'>
                                <a href="https://shahhost.com/order/index.php?/tickets/new/"><FormattedMessage id="contact" defaultMessage="Contact" /></a>
                           </li>
                           <li className='f-li'>
                           <a href="https://shahhost.com/order/index.php?/tickets/new/"><FormattedMessage id="rise-ticket" defaultMessage="Rise A Ticket" /></a>
                           </li>
                           <li className='f-li'>  
                           <a href="/blog"> <FormattedMessage id="blog" defaultMessage="Blogs" /></a>
                           </li>
                         
                        </ul>
                    </div>
                </div>
                <div className="col-right">
                    <img className='footer-logo' src={Logo} alt="logo"/>
                    <h4 className="moto"><FormattedMessage id="moto" defaultMessage="We Make Your Business Professional" /></h4>
                    <br/>
                    <br/>
                    <h3><FormattedMessage id="subscribe" defaultMessage="Subscribe to our mailing list" /></h3>
                    <p><FormattedMessage id="spam" defaultMessage="Dont worry we wont send spam emails" /></p>
                    <Mailchimp 
                        action='https://shahhost.us4.list-manage.com/subscribe/post?u=122e715c1da35daaf2e5a7a67&amp;id=e61a7c3668'
                        fields={[
                            {
                                name: 'EMAIL',
                                placeholder: 'Email',
                                type: 'email',
                                required: true
                            },
                            {
                            name: 'FNAME',
                            placeholder: 'Name',
                            type: 'text',
                            required: false
                            },
                        ]}
                        className='subscribe-input'
                    />
                    <img className='footer-card' src={Card} alt="Card"/>
                </div>
            </div>
            <div className="rithts" style={{paddingBottom: "10px", paddingLeft: "20px"}}>
                        <p style={{color: '#ffffff94' }}><FormattedMessage id="copyrights" defaultMessage="All Right Reserved SHAHhost 2009-2023" /></p>
                    </div>
        </footer>
    )
}

export default Footer;

