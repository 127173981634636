import  React from 'react';
import {FaSearch} from 'react-icons/fa';
import {FormattedMessage} from 'react-intl';

const DomainSearch = () =>{
    return (
        <form method="POST" action="https://shahhost.com/order/index.php?/checkdomain/register-your-domain-now/" 
        className="domain-search">
        <FormattedMessage id="domain-search" defaultMessage="Search for your Domain ...">
    {placeholder=>  
        <input type="text" className="input" placeholder={placeholder}
              autoComplete="off" id="Search" 
              name="domain" />

        }

    </FormattedMessage>
   
         
            <button className="search-button d-search fas fa-search" type="submit" id="Submit"><FaSearch/></button>
         </form>
    )
}

export default DomainSearch;