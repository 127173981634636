import React from 'react'
import './ServicesCard.style.scss';


const ServicesCard = ({name, subtitle, space, band, email, ssl, lock, tieft, reguller, domain, site, price, cent, img, cpanel, core, link}) => {

    return(
        <div className="s-cards grow">
            <h3 className="p-name">{name}</h3>
            <p className="p-name-sub">{subtitle}</p>
                <div className=" p-main">
                    <div className="p-start-item">
                        <p className='p-start'>Starts</p> 
                        <p className='p-from'>from <span className='doller-sign'>$</span> </p> 
                    </div>
                    <p className="p-price">{price}</p>
                    <div className="p-dollor-item">
                        <p className='p-dollor'>.{cent}</p>
                        <p className='p-year'>/mo</p>
                    </div>
                </div>
                <div className="reguller">Reguler: {reguller}</div> <br/>
                <div className="line"></div>
            <ul className="p-ul">
                <li className="p-items">{domain}</li>
                <li className="p-items">{core}</li>
                <li className="p-items">{space}</li>
                <li className="p-items">{band}</li>
                <li className="p-items">{site}</li>
                <li className="p-items">{email}</li>
                <li className="p-items">{ssl}</li>
                <li className="p-items">{lock}</li>
                <li className="p-items">{tieft}</li>
                <li className="p-items">{cpanel}</li>
            </ul>
            <div className="btn grow"><a href={link}>View Planes</a></div>
        </div>
    )
}

export default ServicesCard;
