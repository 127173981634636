import React from 'react'
import '../Servides/QualityCard.style.scss'

const QualityCard = ({title, icon, content}) =>{
    return (
        <div className="q-card-container">
            <img className='q-icon' src={icon} alt=""/>
            <div className="q-contents">
                <h4 className="q-title">{title}</h4>
                <div className="q-text">{content}</div>
            </div>
        </div>
    )
}

export default QualityCard;