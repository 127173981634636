import React, {useState} from "react";
import {IntlProvider} from "react-intl";

import English from './English/en.json';
import Dari from './Dari/da.json';
import Pashto from './Pashto/pa.json';

export const Context = React.createContext();

const local = navigator.language;

let lang;
if(local === "pa"){
    lang = Pashto;
} else if(local === "da"){
    lang = Dari;
}else{
    lang = English;
}



const Wrapper = (props)=>{
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLang (e){
        const newLocale = e.target.value;
        setLocale(newLocale);
        if(newLocale === "pa"){
            setMessages(Pashto)
        }else if(newLocale === "da"){
            setMessages(Dari)
        }else{
            setMessages(English)
        }
    }
    return(
        <Context.Provider value={{locale, selectLang}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}

export default Wrapper;
