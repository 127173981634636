import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import './ErrorPage.style.scss'



const ErrorPage = () => {
    return (
        <div className="error-container" >
            <div className="error-main">

                <div className="card-404">

                    <h1>404</h1>
                    <h4>Page Not Found</h4>
                    <p>It looks like you typed the incorrect URL or the contet been removed</p>
                    <Link to="/" className="btn-primary"> <Button title="Back To Home" /></Link>
                    <Link to="/" className="btn-primary"> <Button title="Contact Us" /></Link>
                    
                </div>
            </div>
        </div>
    )
};


export default ErrorPage;