import React from 'react';
import './DrowerButton.style.scss';
import {MdMenu} from 'react-icons/md'

const SideDroweButton = (props) =>{
    return (
        <button className="toggle-button" onClick={props.click}>
            <div className="toggle-button-line"> <MdMenu /> </div>
           
        </button>
    )
}

export default SideDroweButton;