import React from 'react';
import Particles from 'react-particles-js';

const particalOptions = {
        particles: {
	        number: {
	            value: 50
	        },
	        size: {
	            value: 3
	        }
	    },
	    interactivity: {
	        events: {
	            onhover: {
	                enable: true,
	                mod: "repulse"
	            }
	        }
	    }
}

const Particaless = () =>{

    return (
        <Particles height="400px" params={ particalOptions} />
           
    )
}

export default Particaless;