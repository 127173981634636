import React, { Fragment } from 'react';
import HeroSlider from '../components/hero/HeroSlider';
import DomainSlider from '../components/domainSlider/DomainSlider';
import Services from '../components/Servides/Services';
import Quality from '../components/Servides/Quality';
import WhyUs from '../components/WhyUs/WhyUs';
import Clients from '../components/OurClients/Clients';

const Home = () =>{
    return (
        <Fragment>
           <HeroSlider />
           <DomainSlider />
           <Services />
           <Quality />
           <WhyUs />
           <Clients /> 
        </Fragment>
    )
}

export default  Home;