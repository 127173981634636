import React from 'react'
import './quality.style.scss';
import FreeHosing from '../../images/offers.jpg'
import QualityCard from './QualityCard';
import  Icon1 from '../../images/domain-registration.png'
import  Icon2 from '../../images/development-4png.png'
import  Icon3 from '../../images/hosting.png'
import  Icon4 from '../../images/graphic designn.png'
import { FormattedMessage } from 'react-intl';


const Quality = () =>{
    return (
        <div className="quality-container">
            
            <div className="quality-grid">
                <div className="quality-left">
                    <div className="left-header">
                        <h3> <FormattedMessage id="wide-range" defaultMessage="Wide Range of Products to" /></h3>
                        <h2><FormattedMessage id="products" defaultMessage="Every Web Professional's Needs" /> </h2>
                    </div>
                    <div className="q-left-card">
                        <QualityCard title={<FormattedMessage id="domain-t" defaultMessage="Domain Registration" />}icon={Icon1} 
                            content={<FormattedMessage id="domain-p" defaultMessage="Starts from $0.99/m 
                            .com, .af, .com,af, .net, .co, .biz, .in, .me, .org, and get 
                            naw much more with cheap prices.
                            TLDs, ccTLDs & New. gTLDs for Your Business Reade More" />}  />
                        <QualityCard title={<FormattedMessage id="web-t" defaultMessage="Website Development" />} icon={Icon2} 
                            content={<FormattedMessage id="web-p" defaultMessage="Eye-Catching & Mobile Friendly Web Designs, We provide the broad range
                             of web designs that cover many industries.  The saying “Beauty is in the eye of 
                             beholder”." />}   />
                        <QualityCard title={<FormattedMessage id="hosting-t" defaultMessage="Website Development" />} icon={Icon3} 
                            content={<FormattedMessage id="hosting-p" defaultMessage="Starts from $1.5/m,
                            Host your dream website now with our world class shared web hosting! and comes
                             with all the tools you need we have more then 150 application inside cPanel" />}  />
                        <QualityCard title={<FormattedMessage id="graphic-t" defaultMessage="Graphic Design" />} icon={Icon4} 
                            content={<FormattedMessage id="graphic-p" defaultMessage="Branding/Designing Give your Business a unique Brand 
                            Everything you need Products packs, Logo, Profile, Brochores, Fact Sheet and Much more, as our moto is 'We Make your Business Professional " />}/>
                    </div>

                </div>
                <div className="quality-right">
                  <h3 className="offers-header" ><FormattedMessage id="latest-offer" defaultMessage="Latest Offer" /></h3>
                  <img className="offers" src={FreeHosing} alt=""/>
                  <button className="free-tear grow"><a href="https://shahhost.com/order/index.php?/cart/all-in-one-website-packages/"><i className="fas fa-cut" /><FormattedMessage id="tear" defaultMessage="VIEW PLANES" /></a></button>
                </div>
            </div>
        </div>
    )
}

export default Quality;