import React from 'react';
import './App.css';
import {Switch,Route,} from "react-router-dom";
import Footer from './components/footer/Footer';
import MobileNav from './components/header/MobileNav';
import PrimaryNav from './components/header/PrimaryNav';
import SecondaryNav from './components/header/SecondaryNav';
import Home from './Pages/Home';
import ErrorPage from './Pages/ErrorPage';
import WhatsApp from './components/WhatsApp/WhatsApp';
import AddProducts from './Backend/Services/AddProducts';




const  App = () => {
 
      return (
        <div className="App">
        <PrimaryNav />
        <SecondaryNav />
        <MobileNav  />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/add-products" component={AddProducts} />
          <Route  component={ErrorPage} />
        </Switch>
      <Footer />
      <WhatsApp />
  

      </div>
    );
  
}

export default App;
