import React from 'react';

import DomainSearch from '../domain-search/DomainSearch'
import '../domain-search/domainSearch.style.scss'

import DSlider from '../domainSlider/Slider'
import './DomainSlider.style.scss'



  const DomainSlider = ()=>{
    return(
        <div className="domain-slider-container">
            <div className = 'domain-slider-main'>
            <div className="searchcom">
                <DSlider   />
            </div>
            <div className='domain-spacer'/>
                <DomainSearch />
            </div>

        </div>
    )
    
}

export default DomainSlider;