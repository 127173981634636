import React from 'react';
import './ChatOpen.style.scss';
import Avator from '../../images/WhatsAppIcon-07.png'
import { FaWhatsapp } from 'react-icons/fa';

const ChatOpen = ({click}) => {
    return(
        <div className="whhatsapp-container">
            <div className="whatsapp-main">
                <div className="whatsapp-header">
                    <img src={Avator} alt='logo' />
                    <div className="header-text">
                        <h1>SHAHhost Team  </h1>
                        <span>Typicaly replay imediatly</span>
                    </div>
                    <button onClick={click} className="header-close grow">X</button>
                </div>
                <div className="whatsapp-body">
                    <p className="welcome-message"> </p>
                </div>
                <div className="whatsapp-footer">
                    <div className="icon grow ">
                     <a href="https://wa.me/93799888952?text=Hi, from the Website">
                         <FaWhatsapp className='whatsapp'/> <h5>Start Chating</h5>
                     </a></div>
                </div>
            </div>
        </div>
    )
}

export default  ChatOpen;
