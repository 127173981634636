import React from 'react'
import './Clients.style.scss'
import ClientsSlider from './ClientsSlider';
import {FormattedMessage} from 'react-intl'; 

const Clients = () => {
    return(
        <div className="clients-container containerr">
            <div className="clients-main mainn">
                <div className="clients-header headerr">
                <h3><FormattedMessage id="some-of" defaultMessage="Some of Our clients" /></h3>
                <h1><FormattedMessage id="clients-chosen" defaultMessage="Companies Who chosen us" /></h1>
                
                </div>
                    <div className="clients-logo">
                        <ClientsSlider />
                    </div>
            </div>
        </div>
    )
    
}

export default Clients;
