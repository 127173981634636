import React from 'react';
import './WhatsApp.style.scss';
import ChatOpen from './ChatOpen';
import ChatClose from './ChatClose';

class WhatsApp extends React.Component {

    state ={
        chatOpen: false
    }

    hideChateHandeler = () =>{
        this.setState ({
            chatOpen: false
        })
    }
    showChateHandeler = () =>{
        this.setState ({
            chatOpen: true
        })
    }

    render() {

        return(
            <div className="whhatsapp">
            {
                this.state.chatOpen === true ?
                <ChatOpen click={this.hideChateHandeler} /> :
                <ChatClose click={this.showChateHandeler} />
            }

            </div>
        )
    }
}

export default  WhatsApp;
