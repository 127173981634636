import React from 'react';
import SideDroweButton from './SideDrower/SideDrowerButton';
import SideDrower from './SideDrower/SideDrower';
import BackDrop from './SideDrower/BackDrop';
import MobLogo from '../../images/shahhost-logo.svg';
import './MobileNav.style.scss'
import { Link } from 'react-router-dom';
import Language from './Language';




class MobileNav extends React.Component{

    state={
        SideDrowerOpen: false
      };
    
      drowerClickHandler = () =>{
        this.setState((prevState) =>{
            return {SideDrowerOpen: !prevState.SideDrowerOpen};
          }
        );
      };
      
      backDropHandler =() =>{
        this.setState({SideDrowerOpen: false})
      }
      
      render(){
        let sideDrower;
        let backDrower;
    
        if(this.state.SideDrowerOpen){
          sideDrower = <SideDrower />;
          backDrower = <BackDrop click={this.backDropHandler} />;
        }

    return(
        <div className="mobile-nav-container">
            <div className="mobile-nav-main" >
                <div  className="mobile-nav-items">
                   <Link to="/" > <img src={MobLogo} alt="SHAHhost" className="mobile-nav-logo"/></Link>
                    <div className="spacerrr"></div>
                    <Language />
                    <SideDroweButton  click={this.drowerClickHandler} />
                </div>
                    {sideDrower}
                    {backDrower}
            </div>
        </div>
    )
}
}

export default MobileNav;