import React  from 'react';
import {Link} from "react-router-dom";
import './SecondaryNav.style.scss';
import './dropDoenItems.css';
import {FaHome, FaRocket, FaEnvelope, FaLock, FaGlobe, FaLaptopCode, FaBezierCurve, FaPlug, FaAddressCard, FaAngleDown} from 'react-icons/fa'
import {FormattedMessage} from "react-intl";

const Domain = 'https://shahhost.com/order/index.php?/cart/';
const SecondaryNav = () =>{

     
    return(
        <div className="main">
            <ul className="main-ul">
                <Link to='/' className="items"><FaHome className=" iconn " /><FormattedMessage id="home" defaultMessage="Home" /> </Link>
                <li> <a href={`${Domain}6`} className="items sub-items">< FaRocket className="iconn"/> <FormattedMessage id="hosting" defaultMessage="hosting" /> <FaAngleDown className="iconnn" /> </a> 
                    <div className="menu-items">
                        <ul>
                            <li><a href={`${Domain}ssd-hosting/`}><FormattedMessage id="shared-hosting" defaultMessage="Shared SSD Hosting" /></a></li>
                            <li><a href={`${Domain}cloud-hosting-/`}><FormattedMessage id="cloud-hosting" defaultMessage="Cloud Hosting" /></a></li>
                            <li><a href={`${Domain}wordpress-hosting-/`}><FormattedMessage id="wp-hosting" defaultMessage="wordPress Hosting" /></a></li>
                            <li><a href={`https://shah.af/linux-dedicated-servers-hosting-packages/`}><FormattedMessage id="dedicated-hosting" defaultMessage="Dedicated Servers" /></a></li>
                            <li><a href={`${Domain}ssd-windows-hosting-/`}><FormattedMessage id="windows-hosting" defaultMessage="Windows Hosting" /></a></li>
                            <li><a href={`${Domain}vps-hosting`}><FormattedMessage id="vps-hosting" defaultMessage="VPS Hosting" /></a></li>
                            <li><a href={`${Domain}all-in-one-website-packages/`}><FormattedMessage id="all-in-one" defaultMessage="All in One Planes" /></a></li>
                            <li><a href={`https://shah.af/order/?s_l=en&s_id=272283&plan=64894&period=1`}><FormattedMessage id="managed-servers" defaultMessage=" Managed Servers" /></a></li>
                            <li><a href={`${Domain}reseller-programes`}><FormattedMessage id="reseller-programes" defaultMessage="Reseller Programes" /></a></li>
                        </ul>
                    </div>
                    </li>
                <a href={`https://shahhost.com/order/index.php?/checkdomain/register-your-domain-now/`}className="items"><FaGlobe className=" iconn" /><FormattedMessage id="domain" defaultMessage="Domain" /></a>
                <a href={`${Domain}business-email-pro/`} className="items"><FaEnvelope className=" iconn" /><FormattedMessage id="email-hosting" defaultMessage="Email Hosting" /></a>
                <a href={`${Domain}ssl-certificates-/`} className="items"><FaLock className=" iconn" /><FormattedMessage id="ssl-certificates" defaultMessage="SSL Certificates" /></a>
                <a href={`${Domain}all-in-one-website-packages/`} className="items"><FaLaptopCode className="iconn" /><FormattedMessage id="web-development" defaultMessage="Web Development" /></a>
                <a href={` https://shahoster.com/graphic-designing/`} className="items"><FaBezierCurve className=" iconn" /><FormattedMessage id="graphic-designing" defaultMessage="Graphic Designing" /></a>
                <a href={`https://shahhost.com/order/index.php?/cart/management-systems/`} className="items"><FaPlug className=" iconn" /><FormattedMessage id="plugins" defaultMessage="Softwares CMS" /></a>
                <a href={`${Domain}email-spam-filtering`} className="items"><FaLock className=" iconn" /><FormattedMessage id="email-security" defaultMessage="Email Security" /></a>
                <a href={`https://shahoster.com/portfolio/`} className="items"><FaAddressCard className=" iconn" /><FormattedMessage id="portfolio" defaultMessage="Portfolio" /></a>
            </ul>
        </div>
        
    )
}


export default SecondaryNav;