import React   from "react";
import Slider from "react-slick";
import './Slider.style.scss';
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import {FormattedMessage} from 'react-intl';



const DSlider = ()  => {
 
   
        const settings = {
           // dots: true,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: false,
            arrow: false,
    };

    let mobile = window.matchMedia('(max-width: 500px)');
    let ipod = window.matchMedia('(max-width: 1000px)');
    let disktop = window.matchMedia('(max-width: 1200px)');
    let hd = window.matchMedia('(min-width: 1600px)');

    if(mobile.matches) {
      settings.slidesToShow = 3;
    }

    else if(ipod.matches) {
      settings.slidesToShow = 4;
    }
    
    else if(disktop.matches) {
      settings.slidesToShow = 4;
    }
    else if(hd.matches) {
      settings.slidesToShow = 7;
    }

    return (

        <div className='domain-slider'>
          <Slider {...settings}>
            <div className='domain-price'>
              <h3 className='domain-item'>.com <span className="price">$12/<FormattedMessage id="year" defaultMessage="yr" /></span></h3>
            </div>
            <div className='domain-price'>
              <h3 className='domain-item'>.af <span className="price">$26/<FormattedMessage id="year" defaultMessage="yr" /></span></h3>
            </div>
            <div className='domain-price'>
              <h3 className='domain-item'>.com.af <span className="price">$23/<FormattedMessage id="year" defaultMessage="yr" /></span></h3>
            </div>
            <div className='domain-price'>
              <h3 className='domain-item'>.net <span className="price">$13/<FormattedMessage id="year" defaultMessage="yr" /></span></h3>
            </div>
            <div className='domain-price'>
              <h3 className='domain-item'>.org <span className="price">$13/<FormattedMessage id="year" defaultMessage="yr" /></span></h3>
            </div>
            <div className='domain-price'>
              <h3 className='domain-item'>.me <span className="price">$7/<FormattedMessage id="year" defaultMessage="yr" /></span></h3>
            </div>
            <div className='domain-price'>
              <h3 className='domain-item'>.co <span className="price">$16/<FormattedMessage id="year" defaultMessage="yr" /></span></h3>
            </div>
          </Slider>
        </div>


    );
  
}


export default  DSlider;