import React from 'react'
import {ClientsData} from './ClientsData'; 

import Slider from "react-slick";

const  ClientsSlider =()=> {
  
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false
    };

    let mobile = window.matchMedia('(max-width: 500px)');
    let ipod = window.matchMedia('(max-width: 1000px)');
    let disktop = window.matchMedia('(max-width: 1200px)');
    let hd = window.matchMedia('(min-width: 1600px)');

    if(mobile.matches) {
      settings.slidesToShow = 2;
    }

    else if(ipod.matches) {
      settings.slidesToShow = 4;
    }
    
    else if(disktop.matches) {
      settings.slidesToShow = 6;
    }
    else if(hd.matches) {
      settings.slidesToShow = 9;
    }

    return (
      <div>
        
        <Slider {...settings}>

          <div className="slideItems">
          
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[0].img}/>
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[1].img}/>
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[2].img}/>
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[3].img}/>
          </div>
                    {/* banayee */}
                    <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[22].img} />
          </div>
          
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[13].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[23].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[24].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[4].img}/>
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[5].img}/>
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[15].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[6].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[7].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[8].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[9].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[10].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[11].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[12].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[14].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[16].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[17].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[18].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[19].img} />
          </div>
          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[20].img} />
          </div>

          <div className="slideItems">
            <img alt="Clients" className='clients-images' key={ClientsData.id} 
                src={ClientsData[21].img} />
          </div>


          

        </Slider>
      </div>
    );
  
}


export default ClientsSlider;

