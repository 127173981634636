import React from 'react'
import './Services.style.scss';
import ProductCorsul from './ProductCorusel';
import {FormattedMessage} from 'react-intl';


const Services = () =>{
    return(
        <div className="s-container">

            <div className="s-items">
                <div className="s-header">
                    <h1><FormattedMessage id="chose" defaultMessage="Chose the perfect hosting plane" /></h1>
                    <p className='s-header-sub'><FormattedMessage id="weather" defaultMessage="Whether you are Developer, Designer, want your own web business 
                    or want a Website for your business just chose a sortable plane, Our Services are more 
                        Reliable and Fast you no need to think so much." /> </p>
                </div>
                           
                <div className="crusel">
                   <ProductCorsul />
                </div>
            </div>
        </div>
    )
}


export default Services;