import React from 'react';
import './ChatClose.style.scss';
import {FaWhatsapp} from 'react-icons/fa'

const ChatClose = ({click}) => {
    return(
        <div onClick={click} className="chatclose-container grow">
            <div className="chatclose-main">
                    <div className="icon  ">
                         <FaWhatsapp className='whatsapp' /> 
                     </div>
            </div>
        </div>
    )
}

export default  ChatClose;
