import React from 'react';
import Hero1 from '../../images/header.png'
import './HeroSlider.style.scss'
import Particaless from '../Particaless';
import {FormattedMessage} from "react-intl"


const HeroSlider = () =>{

    return (
        <div className="hero">
<a href="https://shahhost.com/order/index.php?/cart/ssd-hosting/"> 
            <div  style={{ backgroundImage:`url(${Hero1})` }} className="slides" >
        <Particaless className="particle" />
                <div className="offers-hero">
                    <button className="offerButton"> <FormattedMessage id="mega" defaultMessage="Spring Sale 55% Off" /></button>
                    <h1 className="offer-headin"> <FormattedMessage id="offer" defaultMessage="SHARED HOSTING" /></h1>
                    <h3><FormattedMessage id="inclouds" defaultMessage="Free Inclouds SSL & Site Lock" /></h3>
                    <div className="price-hero">
                        <div id="price">
                            <div className="start-from">
                                <h4 className=""><FormattedMessage id="starts" defaultMessage="STARTS" /></h4>
                                <h4 className=""><FormattedMessage id="from" defaultMessage="FROM" /></h4>
                            </div>
                            <h1 className="price2-hero"><span className="dollorSign">$</span> <span className="mainPrice">1</span>  <span className="sent">.25</span></h1>
                        </div>
                        <h3 className="perYear"><FormattedMessage id="per-year" defaultMessage="Per Year" /></h3>
                    </div>
                </div>
            
            </div>
            <Particaless /></a>
        </div>
    )
}

export default HeroSlider;