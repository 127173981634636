import React from 'react'
import { FormattedMessage } from 'react-intl';
import './WhyUs.style.scss';
import WhyUsCards from './WhyUsCards';


import Support from '../../images/whyUs/shah-host-icons-03.svg';
import PayLess from '../../images/whyUs/shah-host-icons-02.svg';
import Experiance from '../../images/whyUs/shah-host-icons-04.svg';
import Delevry from '../../images/whyUs/shah-host-icons-05.svg';
import HighSpeed from '../../images/whyUs/shah-host-icons-06.svg';
import Lock from '../../images/whyUs/shah-host-icons-07.svg';
import Client from '../../images/whyUs/shah-host-icons-08.svg';
import Migration from '../../images/whyUs/shah-host-icons-09.svg';



const WhyUs = ()=>{
    return (
        <div className="why-us-container containerr">
            <div className="why-us-main mainn">
                <div className="why-us-header headerr ">
                    <h1 className="header"><FormattedMessage id="why-us" defaultMessage="Why Chose Us ?" /></h1>
                    <p className="why-us-content"><FormattedMessage id="why-us-p" defaultMessage="SHAHhost is fully registered with Afghanistan Ministry
                     of Commerce & Industries and Afghanistan Ministry of Information and Technology 
                     having license no. 45590. We are working since 15 years, SHAHhost is a strong and 
                     solid ICT Solution, Web Services
                     & Graphic Designing Company in Afghanistan" /> <a href="/about"><FormattedMessage id="why-us-more" defaultMessage="Read More" /></a></p>
                </div>

                    <div className="why-us-carditems">
                                    <WhyUsCards
                                    icon={Support} 
                                    title={<FormattedMessage id="a-why-us-t" defaultMessage="24/7 SUPPORT"/>} 
                                    contents={<FormattedMessage id="a-why-us-p" defaultMessage="Our expert team is always on hand to help answer your questions, You can call, chat, rise a ticket or email us any time!"/>} 
                                    />
                                    <WhyUsCards
                                    icon={PayLess} 
                                    title={<FormattedMessage id="b-why-us-t" defaultMessage="Pay Less Get More !"/>} 
                                    contents={<FormattedMessage id="b-why-us-p" defaultMessage="Highly competitive Pricing, If you’re not 100% satisfied with SHAHhost, we’ll refund your payment. No hassle, no risk."/>} 
                                    />
                                    <WhyUsCards
                                    icon={Experiance} 
                                    title={<FormattedMessage id="c-why-us-t" defaultMessage="15 Years of Experience"/>} 
                                    contents={<FormattedMessage id="c-why-us-p" defaultMessage="A personal company “SHAH Host ICT” was found by Amir SHAH who been working since 16 years in the fields."/>} 
                                    />
                                    <WhyUsCards
                                    icon={Delevry} 
                                    title={<FormattedMessage id="d-why-us-t" defaultMessage="On-time Delivery"/>} 
                                    contents={<FormattedMessage id="d-why-us-p" defaultMessage="Meeting customer’s expectations and delivery requirements has to be a priority for every business, for what we care about."/>} 
                                    />
                                    <WhyUsCards
                                    icon={HighSpeed} 
                                    title={<FormattedMessage id="e-why-us-t" defaultMessage="High Speed Servers"/>} 
                                    contents={<FormattedMessage id="e-why-us-p" defaultMessage="Bundled with faster read/write speeds and terabyte level capacity to give your sites the performance they need."/>} 
                                    />
                                    <WhyUsCards
                                    icon={Lock} 
                                    title={<FormattedMessage id="f-why-us-t" defaultMessage="High Secutiry"/>} 
                                    contents={<FormattedMessage id="f-why-us-p" defaultMessage="We are running high level security programes to secure our servers, from DDos attack, email protection by ClamAV"/>} 
                                    />
                                    <WhyUsCards
                                    icon={Client} 
                                    title={<FormattedMessage id="g-why-us-t" defaultMessage="Up-to 500 clients"/>} 
                                    contents={<FormattedMessage id="g-why-us-p" defaultMessage="Our beloved clients count is increasing day by day, we always respect our clients thats why they are increasing."/>} 
                                    />
                                    <WhyUsCards
                                    icon={Migration} 
                                    title={<FormattedMessage id="h-why-us-t" defaultMessage="Free Migration"/>} 
                                    contents={<FormattedMessage id="h-why-us-p" defaultMessage="To expedite your hosting venture with us, we will help you move your existing account to our cPanel hosting platform."/>} 
                                    />
                    </div>
            </div>
        </div>
    )
}

export default WhyUs;