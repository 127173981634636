import React from 'react'
import {ServicesData} from './ServicesData'; 
import ServicesCard from './ServicesCard';

import Slider from "react-slick";

export default class ProductCorsul extends React.Component {
  render() {


    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false
    };

    

    let mobile = window.matchMedia('(max-width: 500px)');
    let ipod = window.matchMedia('(max-width: 1000px)');
    let disktop = window.matchMedia('(max-width: 1200px)');
    let hd = window.matchMedia('(min-width: 1600px)');

    if(mobile.matches) {
      settings.slidesToShow = 1;
    }

    else if(ipod.matches) {
      settings.slidesToShow = 2;
    }
    
    else if(disktop.matches) {
      settings.slidesToShow = 3;
    }
    else if(hd.matches) {
      settings.slidesToShow = 5;
    }

    return (
      <div >
        
        <Slider {...settings} >
          <div className="slideItems">
          
            <ServicesCard key={ServicesData.id} 
                                name={ServicesData[0].name} 
                                subtitle={ServicesData[0].subtitle} 
                                site={ServicesData[0].site} 
                                img="" space={ServicesData[0].space} 
                                band={ServicesData[0].band}
                                core={ServicesData[0].core}
                                domain={ServicesData[0].domain} 
                                lock={ServicesData[0].lock} 
                                ssl={ServicesData[0].ssl} 
                                tieft={ServicesData[0].tieft}
                                cpanel={ServicesData[0].cpanel}
                                price={ServicesData[0].price} 
                                cent={ServicesData[0].cent}
                                email={ServicesData[0].email}
                                reguller={ServicesData[0].reguller}
                                link={ServicesData[0].link}
                            />
          </div>
          <div className="slideItems">
            <ServicesCard key={ServicesData.id} 
                                name={ServicesData[1].name} 
                                subtitle={ServicesData[1].subtitle} 
                                site={ServicesData[1].site} 
                                img="" space={ServicesData[1].space} 
                                band={ServicesData[1].band}
                                core={ServicesData[1].core}
                                domain={ServicesData[1].domain} 
                                lock={ServicesData[1].lock} 
                                ssl={ServicesData[1].ssl} 
                                tieft={ServicesData[1].tieft}
                                cpanel={ServicesData[1].cpanel}
                                price={ServicesData[1].price} 
                                cent={ServicesData[1].cent}
                                email={ServicesData[1].email}
                                reguller={ServicesData[1].reguller}
                                link={ServicesData[1].link}
                            />
            </div>
            <div className="slideItems">
                <ServicesCard key={ServicesData.id} 
                                    name={ServicesData[2].name} 
                                    subtitle={ServicesData[2].subtitle} 
                                    site={ServicesData[2].site} 
                                    img="" space={ServicesData[2].space} 
                                    band={ServicesData[2].band}
                                    core={ServicesData[2].core}
                                    domain={ServicesData[2].domain} 
                                    lock={ServicesData[2].lock} 
                                    ssl={ServicesData[2].ssl} 
                                    tieft={ServicesData[2].tieft}
                                    cpanel={ServicesData[2].cpanel}
                                    price={ServicesData[2].price} 
                                    cent={ServicesData[2].cent}
                                    email={ServicesData[2].email}
                                    reguller={ServicesData[2].reguller}
                                    link={ServicesData[2].link}
                                />
             </div>
             <div className="slideItems"> 
                <ServicesCard key={ServicesData.id} 
                                    name={ServicesData[3].name} 
                                    subtitle={ServicesData[3].subtitle} 
                                    site={ServicesData[3].site} 
                                    img="" space={ServicesData[3].space} 
                                    band={ServicesData[3].band}
                                    core={ServicesData[3].core}
                                    domain={ServicesData[3].domain} 
                                    lock={ServicesData[3].lock} 
                                    ssl={ServicesData[3].ssl} 
                                    tieft={ServicesData[3].tieft}
                                    cpanel={ServicesData[3].cpanel}
                                    price={ServicesData[3].price} 
                                    cent={ServicesData[3].cent}
                                    email={ServicesData[3].email}
                                    reguller={ServicesData[3].reguller}
                                    link={ServicesData[3].link}
                                />
             </div>
             <div className="slideItems">
                <ServicesCard  key={ServicesData.id} 
                                    name={ServicesData[4].name} 
                                    subtitle={ServicesData[4].subtitle} 
                                    site={ServicesData[4].site} 
                                    img="" space={ServicesData[4].space} 
                                    band={ServicesData[4].band}
                                    core={ServicesData[4].core}
                                    domain={ServicesData[4].domain} 
                                    lock={ServicesData[4].lock} 
                                    ssl={ServicesData[4].ssl} 
                                    tieft={ServicesData[4].tieft}
                                    cpanel={ServicesData[4].cpanel}
                                    price={ServicesData[4].price} 
                                    cent={ServicesData[4].cent}
                                    email={ServicesData[4].email}
                                    reguller={ServicesData[4].reguller}
                                    link={ServicesData[4].link}
                                />
              </div>
              <div className="slideItems"> 
                <ServicesCard key={ServicesData.id} 
                                    name={ServicesData[5].name} 
                                    subtitle={ServicesData[5].subtitle} 
                                    site={ServicesData[5].site} 
                                    img="" space={ServicesData[5].space} 
                                    band={ServicesData[5].band}
                                    core={ServicesData[5].core}
                                    domain={ServicesData[5].domain} 
                                    lock={ServicesData[5].lock} 
                                    ssl={ServicesData[5].ssl} 
                                    tieft={ServicesData[5].tieft}
                                    cpanel={ServicesData[5].cpanel}
                                    price={ServicesData[5].price} 
                                    cent={ServicesData[5].cent}
                                    email={ServicesData[5].email}
                                    reguller={ServicesData[5].reguller}
                                    link={ServicesData[5].link}
                                />
              </div>
             <div className="slideItems"> 
                <ServicesCard key={ServicesData.id} 
                                    name={ServicesData[6].name} 
                                    subtitle={ServicesData[6].subtitle} 
                                    site={ServicesData[6].site} 
                                    img="" space={ServicesData[6].space} 
                                    band={ServicesData[6].band}
                                    core={ServicesData[6].core}
                                    domain={ServicesData[6].domain} 
                                    lock={ServicesData[6].lock} 
                                    ssl={ServicesData[6].ssl} 
                                    tieft={ServicesData[6].tieft}
                                    cpanel={ServicesData[6].cpanel}
                                    price={ServicesData[6].price} 
                                    cent={ServicesData[6].cent}
                                    email={ServicesData[6].email}
                                    reguller={ServicesData[6].reguller}
                                    link={ServicesData[6].link}
                                />

              </div>
             <div className="slideItems"> 
                <ServicesCard key={ServicesData.id} 
                                    name={ServicesData[7].name} 
                                    subtitle={ServicesData[7].subtitle} 
                                    site={ServicesData[7].site} 
                                    img="" space={ServicesData[7].space} 
                                    band={ServicesData[7].band}
                                    core={ServicesData[7].core}
                                    domain={ServicesData[7].domain} 
                                    lock={ServicesData[7].lock} 
                                    ssl={ServicesData[7].ssl} 
                                    tieft={ServicesData[7].tieft}
                                    cpanel={ServicesData[7].cpanel}
                                    price={ServicesData[7].price} 
                                    cent={ServicesData[7].cent}
                                    email={ServicesData[7].email}
                                    reguller={ServicesData[7].reguller}
                                    link={ServicesData[7].link}
                                />

              </div>
          

        </Slider>
      </div>
    );
  }
}