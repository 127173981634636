import React from 'react';
import  './PrimaryNave.style.scss';
import Logo from '../../images/shahhost-logo.svg'

import {Link, withRouter} from 'react-router-dom';
import Language from './Language';
import {FormattedMessage} from "react-intl";



import {Button} from '../Button';
import { FaUser, FaBook, FaTwitterSquare, FaLinkedin, FaYoutubeSquare, FaFacebookSquare, FaCartArrowDown } from 'react-icons/fa';

// import { FaSquareXTwitter } from "react-icons/fa6";

const PrimaryNav = () =>{
    return (
        <header className="container">
            <div className="left-nav">
               <Link className="logo-link" to="/" ><img src={Logo} alt="logo" className="logo" /> </Link>
                {/* <DomainSearch /> */}

            </div>

            <div className="spacer-nav"></div>
                <Language />

            <div className="right-nav">
            <div className="line">|</div>
                <div className="social-main support-nav">
                   <a href="https://shahhost.com/order/index.php?/tickets/new/"><FaUser className="social2"/><FormattedMessage id="support" defaultMessage="Support" /> </a>
                   <a href="https://shahhost.com/order/index.php?/knowledgebase/"><FaBook className="social2" /><FormattedMessage id="blog" defaultMessage="Blog" /> </a>
                </div>
                <div className="line">|</div>

                <div className="social-main">
                    <a href="https://www.linkedin.com/company/shahhostt" target="_blank"rel="noopener noreferrer" ><FaLinkedin className="social "/></a> 
                    <a href="https://twitter.com/SHAHhost/"  target="_blank"rel="noopener noreferrer" ><FaTwitterSquare className="social "/></a> 
                    <a href="https://www.youtube.com/channel/UCi4G3reGTGYbcswmFuyHrGw" target="_blank" rel="noopener noreferrer" ><FaYoutubeSquare className="social "/></a> 
                    <a href="https://www.facebook.com/shahhost/" target="_blank" rel="noopener noreferrer" ><FaFacebookSquare className="social " /></a> 
                </div>
                <div className="line">|</div>
              <a href="https://shahhost.com/order/index.php?/cart/"><FaCartArrowDown className="cart "></FaCartArrowDown></a> 
              <a href="https://shahhost.com/order/index.php?/clientarea/">  <Button  id="signIn" title={<FormattedMessage id="sign-in" defaultMessage="Sign In" />}/></a>
              <a href="https://shahhost.com/order/index.php?/signup/">  <Button  className="signUp" title={<FormattedMessage id="sign-up" defaultMessage="Sign Up" />}/></a>
            </div>
        </header>
    )
}

export default withRouter (PrimaryNav);