import Client01 from '../../images/Clients/shahhost-clients-01.png'
import Client02 from '../../images/Clients/shahhost-clients-02.png'
import Client1 from '../../images/Clients/shahhost-clients-12.png'
import Client2 from '../../images/Clients/shahhost-clients-13.png'
import Client3 from '../../images/Clients/shahhost-clients-14.png'
import Client4 from '../../images/Clients/shahhost-clients-15.png'
import Client5 from '../../images/Clients/shahhost-clients-16.png'
import Client6 from '../../images/Clients/shahhost-clients-17.png'
import Client7 from '../../images/Clients/shahhost-clients-18.png'
import Client8 from '../../images/Clients/shahhost-clients-19.png'
import Client9 from '../../images/Clients/shahhost-clients-20.png'
import Client10 from '../../images/Clients/shahhost-clients-21.png'
import Client11 from '../../images/Clients/shahhost-clients-22.png'
import Client12 from '../../images/Clients/shahhost-clients-23.png'
import Client13 from '../../images/Clients/shahhost-clients-24.png'
import Client14 from '../../images/Clients/shahhost-clients-25.png'
import Client15 from '../../images/Clients/shahhost-clients-26.png'
import Client16 from '../../images/Clients/shahhost-clients-27.png'
import Client17 from '../../images/Clients/shahhost-clients-28.png'
import Client18 from '../../images/Clients/shahhost-clients-29.png'
import Client19 from '../../images/Clients/shahhost-clients-30.png'
import Client20 from '../../images/Clients/shahhost-clients-31.png'
import Client21 from '../../images/Clients/shahhost-clients-32.png'
import Client22 from '../../images/Clients/shahhost-clients-33.png'
import Client23 from '../../images/Clients/shahhost-clients-34.png'



export const ClientsData =[
    {
        id: 0,
        img: `${Client1}`,
    },
    {
        id: 1,
        img: `${Client2}`,
    },
    {
        id: 2,
        img: `${Client3}`,
    },
    {
        id: 3,
        img: `${Client4}`,
    },
    {
        id: 4,
        img: `${Client5}`,
    },
    {
        id: 5,
        img: `${Client6}`,
    },
    {
        id: 6,
        img: `${Client7}`,
    },
    {
        id: 7,
        img: `${Client8}`,
    },
    {
        id: 8,
        img: `${Client9}`,
    },
    {
        id: 9,
        img: `${Client10}`,
    },
    {
        id: 10,
        img: `${Client11}`,
    },
    {
        id: 11,
        img: `${Client12}`,
    },
    {
        id: 12,
        img: `${Client13}`,
    },
    {
        id: 13,
        img: `${Client14}`,
    },
    {
        id: 14,
        img: `${Client15}`,
    },
    {
        id: 15,
        img: `${Client16}`,
    },
    {
        id: 16,
        img: `${Client17}`,
    },
    {
        id: 17,
        img: `${Client18}`,
    },
    {
        id: 18,
        img: `${Client19}`,
    },
  
    {
        id: 19,
        img: `${Client20}`,
    },
    {
        id: 20,
        img: `${Client21}`,
    },
    {
        id: 21,
        img: `${Client22}`,
    },
    {
        id: 22,
        img: `${Client23}`,
    },
    {
        id: 23,
        img: `${Client01}`,
    },
    {
        id: 24,
        img: `${Client02}`,
    },
  
    
]